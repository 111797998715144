import Cookies from 'js-cookie'
const cookies = {}
/**
 * @description 存储 cookie 值
 * @param {String} name cookie name
 * @param {String} value cookie value
 * @param {Object} setting cookie setting
 */
cookies.set = function (name = 'default', value = '', cookieSetting = {}) {
  // 设置过期时间，默认7天
  // new Date(new Date().getTime() + 120 * 60 * 1000)
  const currentCookieSetting = {
    expires: 7
  }
  Object.assign(currentCookieSetting, cookieSetting)
  Cookies.set(`${name}`, value, currentCookieSetting)
}

/**
 * @description 拿到 cookie 值
 * @param {String} name cookie name
 */
cookies.get = function (name = 'default') {
  return Cookies.get(`${name}`)
}

/**
 * @description 拿到 cookie 全部的值
 */
cookies.getAll = function () {
  return Cookies.get()
}

/**
 * @description 删除 cookie
 * @param {String} name cookie name
 */
cookies.remove = function (name = 'default') {
  return Cookies.remove(`${name}`)
}

export default cookies
