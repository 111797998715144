import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/login",
    name: "Login",
    component: () => import("../views/login.vue"),
    meta: {
      title: "登录页",
    },
  },

  {
    path: "/",
    name: "Home",
    redirect:"/index",
    component: () => import("../layout/index"),
    meta: { title: "首页", keepAlive: false },
    children: [
      {
        path: "/index",
        name: "Index",
        component: () => import("../views/Home.vue"),
        meta: {
          title: "首页",
        },
      },
      {
        path: "/studentlist",
        name: "StudentList",
        component: () => import("../views/studentlist.vue"),
        meta: {
          title: "学生列表",
        },
      },
      {
        path: "/video",
        name: "Video",
        component: () => import("../views/video.vue"),
        meta: {
          title: "视频详情",
        },
      },
      {
        path: "/adduser",
        name: "Adduser",
        component: () => import("../views/adduser.vue"),
        meta: {
          title: "新增学生",
        },
      },
    ],
  },
];

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes,
});

export default router;
