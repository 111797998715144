import router from "./router";
import cookie from "@/utils/cookie";
// 当前用户是否登录
router.beforeEach((to, form, next) => {
  window.document.title = to.meta.title; // 设置title名
  next(); 
  if (to.path == "/login") cookie.remove("token"); //如果当前路由回到登录页 默认清空过期token
    let user = cookie.get("token");
    if (!user && to.path !== "/login") {
      // 如果当前token不为空且当前路由不是登录页
      next({ path: "/login" });
    } else {
      next();
    }
});

 
// 跳转页面后统一回到顶部
router.afterEach(() => {
  window.scrollTo(0, 0);
});
