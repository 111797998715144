import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import ElementUI from 'element-ui';
import cookies from "@/utils/cookie";
// import TCPlayer from 'tcplayer.js';
// import 'tcplayer.js/dist/tcplayer.min.css';
import 'element-ui/lib/theme-chalk/index.css';
import "normalize.css";
import "@/mixins/index.js"
import "@/permission.js";

Vue.config.productionTip = false;
Vue.use(ElementUI);
// Vue.prototype.$TCPlayer = TCPlayer;
Vue.prototype.$cookie = cookies;
new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
