<template>
  <div class="home">

    <div class="list">
      <div class="flex_list">
        <div class="title">为您推荐</div>

        <div class="div" v-if="adminType == 1"><el-input placeholder="请输入需要查询的视频名称" v-model="SearchVal" clearable
            @keyup.enter.native="Search()"></el-input>
          <div class="fixed_btn"><el-button @click="Search()" type="primary">搜索</el-button></div>
        </div>

        <div class="admin" @click="handleRouter('/adduser')" v-if="adminType == 1"> <el-button
            type="primary">学生权限管理</el-button></div>
      </div>

      <div class="video_list">
        <div class="video" @click="handleRouter(`/video?id=${item.id}`)" v-for="(item, index) in ListData" :key="index">
          <!-- <img src="https://puui.qpic.cn/vpic_cover/h0040b2qizl/h0040b2qizl_hz.jpg/640" alt=""> -->
          <img src="../assets/banner.png" alt="banner">
          <div class="maxwidth">
            <div class="name">{{ item.name }}</div>
            <div class="desc">视频节数：{{ item.videos_count }}节</div>
          </div>
        </div>
      </div>
      <div style="width: 100%;margin: 0 auto;">
        <el-pagination background layout="prev, pager, next" :total="Number(this.Datalength)" @current-change="HandleChange">
        </el-pagination>
      </div>

      <div class="width50"></div>
    </div>
  </div>
</template>

<script>
import { SearchList } from "@/api/home"
export default {
  name: "Home",
  data() {
    return {
      Datalength:'',
      SearchVal: "",
      adminType: this.$cookie.get("type"), //当前用户是管理员还是学生
      ListData: [{
        id: 1,
        name: "全优课堂-出国教育",
        desc: "",
        img: "https://puui.qpic.cn/vpic_cover/w003700o34q/w003700o34q_hz.jpg/640"
      }],
    }
  },
  mounted() {
    this.HandleData();
  },
  methods: {
    HandleChange(val){
      SearchList({page:val,name:this.SearchVal}).then(res => {
        if(res.code == 200){
          this.ListData = res.data.data;
        }
      });
    },
    Search() {
      SearchList({ name: this.SearchVal }).then(res => {
        this.ListData = res.data.data;
        this.Datalength = res.data.total
      });
    },
    HandleData() {
      SearchList().then(res => {
        this.ListData = res.data.data;
        this.Datalength = res.data.total
      });
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-pagination{
  text-align: center;
}
.width50 {
  width: 100%;
  height: 50px;
}

.div {
  position: relative;
}

.fixed_btn {
  position: absolute;
  right: 0px;
  top: 0px;
}

/deep/.el-input--suffix .el-input__inner {
  width: 480px;
}

.flex_list {
  margin-top: 40px;
  display: flex;
  justify-content: space-between;
}

.list {
  width: 1200px;
  margin: 0 auto;

  .title {
    font-weight: bold;
    font-size: 30px;
    line-height: 42px;
    color: #333;
  }

  .video_list {
    width: 100%;
    margin: 30px auto;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .video {
      width: 32%;
      height: 300px;
      background-color: rgb(43, 43, 43);
      border-radius: 10px;
      margin: 0 0 20px 0;
      cursor: pointer;
      border: 1px solid #eee;

      img {
        width: 100%;
        height: 216px;
        background: #fff;
        object-fit: contain;
        border-radius: 10px 10px 0 0;
      }

      .maxwidth {
        width: 90%;
        margin: 0 auto;
        color: #fff;

        .name {
          font-size: 20px;
          color: hsla(0,0%,100%,.9);
          letter-spacing: 0;
          height: 28px;
          line-height: 28px;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          font-weight: bold;
          margin: 10px 0 6px 0;
        }
        .name:hover{
          color: #0d9e72;
        }
        .desc {
          font-size: 16px;
          color: hsla(0,0%,100%,.5);
          letter-spacing: 0;
          margin-top: 5px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          line-height: 20px;
        }
      }
    }

  }
}
</style>
