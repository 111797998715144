import request from '@/utils/axios'
// 注：上传文件时，把 params 改成 data
// 查询用户数据
export function SearchData (params) {
  return request({
    url: '/api/video/index',
    method: 'get',
    params
  })
}

// 查询视频列表 
export function SearchList (params) {
    return request({
      url: '/api/video/typeIndex',
      method: 'get',
      params
    })
  }
  // 消耗视频观看次数

  export function ConsumeVideoTime (params) {
    return request({
      url: '/api/video/consumeVideoTime',
      method: 'post',
      params
    })
  }

  // 记录下载次数

  export function DownWatch(params){
    return request({
      url: '/api/video/downVideoLog',
      method: 'post',
      params
    })
  }