import Vue from "vue";
const myMixin = {
  methods: {
    handleRouter(path, query) {
      this.$router.push({
        path,
        query,
      });
    },
  },
};
Vue.mixin(myMixin);
